/*----------------------------
    1. Place holder
-----------*/

%clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

%cubic-transition-5s {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
%gradient-overlay-blue {
background: -moz-linear-gradient(top, rgba(2,83,126,0.8) 0%, rgba(2,83,126,1) 95%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(2,83,126,0.8) 0%,rgba(2,83,126,1) 95%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(2,83,126,0.8) 0%,rgba(2,83,126,1) 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

%transition {
    transition: all .5s linear;
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -ms-transition: all .5s linear;
    -o-transition: all .5s linear;
}
%transition-ease {
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
%transition-2s {
    transition: all .25s linear;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
}



%appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}



%border-none {
    border: none;
}


%center-text {
    position: absolute;
    margin: auto;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

%center-text-both {
    position: absolute;
    margin: auto;
    text-align: center;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

%heading-zero-margin {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }
}

%box-reset-padding {
    &.npd {
        padding: 0;
    }
    &.npt {
        padding-top: 0;
    }
    &.npb {
        padding-bottom: 0;
    }
    &.npl {
        padding-left: 0;
    }
    &.npr {
        padding-right: 0;
    }
}

%box-reset-margin {
    &.nm {
        margin: 0;
    }
    &.nmt {
        margin-top: 0;
    }
    &.nmb {
        margin-bottom: 0;
    }
    &.nml {
        margin-left: 0;
    }
    &.nmr {
        margin-right: 0;
    }
}

%bg-colors {
    &.white {
        background: $color-white;
    }
    &.grey {
        background: $color-black;
    }
}
/*----------------------------
    2. Mixins
-----------*/

@mixin overlay($bg, $op, $z) {
    background: rgba($bg, $op);
    z-index: $z;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 100%;
}

@mixin vertical-align($position, $top) {
    @extend %center-text;
    position: $position;
    top: $top;
}




@mixin height($height) {
    height: $height;
}

@mixin max-width($w) {
    max-width: $w;
}

@mixin min-width($min) {
    min-width: $min;
}


@mixin radius($r) {
    border-radius: $r;
}

@mixin min-width($wid) {
    min-width: $wid;
}

@mixin translate($value, $dir) {
    @if $dir==transy {
        transform: translateY($value);
        -webkit-transform: translateY($value);
        -moz-transform: translateY($value);
        -ms-transform: translateY($value);
        -o-transform: translateY($value);
    }
    @else if $dir==transx {
        transform: translateX($value);
        -webkit-transform: translateX($value);
        -moz-transform: translateX($value);
        -ms-transform: translateX($value);
        -o-transform: translateX($value);
    }
    @else if $dir==rot {
        transform: rotate($value);
        -webkit-transform: rotate($value);
        -moz-transform: rotate($value);
        -ms-transform: rotate($value);
        -o-transform: rotate($value);
    }
    @else if $dir==scle {
        transform: scale($value);
        -webkit-transform: scale($value);
        -moz-transform: scale($value);
        -ms-transform: scale($value);
        -o-transform: scale($value);
    }
    @else if $dir==skew {
        transform: skewX($value);
        -webkit-transform: skewX($value);
        -moz-transform: skewX($value);
        -ms-transform: skewX($value);
        -o-transform: skewX($value);
    }
}


// Columns
@mixin rowMachine($row, $margin, $marginBtm) {
    width: ((100% - (($row - 1) * $margin)) / $row);
    &:nth-child(n) {
        margin-bottom: $marginBtm;
        margin-right: $margin;
    }
    &:nth-child(#{$row}n) {
        margin-right: 0;

    }
}

@mixin hover-state {
    &:hover, &:active, &:focus {
        @content;
    }
}

@mixin breakpoint($class) {
    @if $class == sm-mobile {
    @media (max-width: 640px) { @content; }
  }
  @if $class == mobile {
    @media (max-width: 767px) { @content; }
  }
 @else if $class == tablet-only {
    @media (min-width: 768px) and (max-width:1024px) { @content; }
  }
  @else if $class == tablet {
    @media (min-width: 768px) { @content; }
  }


  @else if $class == md {
    @media (min-width: 1025px) { @content; }
  }
   @else if $class == medium-only {
    @media (min-width: 1025px) and (max-width:1199px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }
  @else if $class == xlg {
    @media (min-width: 1510px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

