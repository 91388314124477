.mapWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 0px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.form-control {
  &.error {
    color: #e43d3d;
  }
}

label {
  &.error {
    color: #e43d3d!important;
  }
}

form {
  .form-group {
    label {
      font-size: 11px;
      color: $color-primary;
      text-transform: uppercase;
      font-weight: normal;
      padding-bottom: 10px;
      span {
        color: $color-secondary;
        padding-left: 5px;
      }
    }
    input:not([type="checkbox"]),
    textarea {
      height: 48px;
      background: $color-lightblue;
      font-weight: 300;
      border: none;
      box-shadow: none;
      border-radius: 0;
      color: $color-primary-75;
      -webkit-appearance: none;
    }
    textarea {
      resize: none;
      width: 100%;
      min-height: 176px;
    }
  }
}

.FormElement_checkbox {
  display: flex;
  align-items: center;
  padding: 17px 0;
  span {
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    color: black;
    font-weight: normal;
  }
  input {
    margin: 0;
    height: auto;
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

.FormElement_select {
  height: 48px;
  background: #E5EDF2;
  
  select {
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    font-weight: 300;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: rgba(2, 83, 126, 0.75);
    -webkit-appearance: none;
  }
}

.FormElement_upload {
  > label {
    width: 100%;
    padding: 18.5px 12px 18.5px 12px!important;
    background: $color-lightblue;
    font-weight: 300;
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: $color-primary-75;
    -webkit-appearance: none;
    margin-bottom: 0!important;
  }
  
  input {
    position: absolute;
    height: .1px!important;
    width: .1px!important;
    overflow: hidden;
    z-index: -99999;
    opacity: .01;
  }
}

.contact-sec {
  .contact-us-left {
    padding-top: 40px;
    padding-bottom: 40px;
    float: left;
    width: 100%;
    @include breakpoint(md){
       width: 50%;
        padding-top: 80px;
    padding-bottom: 80px;
    }
    p {
      font-size: $font-h4;
      line-height: 28px;
    }
    address {
      @extend p;
      margin-bottom: 0;
    }
    .contact-info {
      padding-bottom: 60px;
    }
  }
  .contact-us-right {
    position: relative;
    right: 0;
    width: 100%;
    bottom:0;
    height: 100%;
    float: left;
    margin-bottom: 50px;

    @include breakpoint(md){
       width: 45%;
       position: absolute;
       margin-bottom: 0

    }
    .mapWrapper {
      position: relative;
      padding-bottom:0;
      /* 16:9 */
      padding-top: 0;
      height: 0;
       @include breakpoint(md){
       position: relative;
      padding-bottom:0%;
      /* 16:9 */
      padding-top: 0;
      height: 100%;
    }
    }
    .mapWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.full-col {
  margin-bottom: 10px;
  @extend %clearfix;
  .form-group {

    @include breakpoint(mobile){
      padding: 0;
    }
     @include breakpoint(tablet){
      padding: 0;
    }

    @include breakpoint(md){
      padding-left:15px;
    }
    &:first-child {
      padding-left: 0;
    }
  }
}
