// - Maps default behaviour

.js-map {
	width:100%;
	min-height:300px;
	height:100%;
	background-color:lighten($color-lightblue, 10%);
	position:relative;
	&:before {
		position:absolute;
		content:"MAP";
		opacity:0.5;
		top:50%;
		left:50%; 
		transform: translate(-50%, -50%);
	}
	.marker-collection {
		display: none;
	}
}