/**

    IMAGE BLOCK:
    - Image block used in program page
    - Team block used in team page
    - Event block used in team page
 */


/**
 *
 * Image Block
 *
 */

.img-block {
    >a {
        display: block;

    }
    overflow: hidden;
    @extend %transition-ease;
    figure {
        overflow: hidden;
        position: relative;
        figcaption {
            @extend %center-text-both;
            padding: 20px;
            width: 100%;
            z-index: 2;
        }
        &:after {
            @include overlay($color-black, .4, 1);
            @extend %transition-ease;
            // background: -moz-radial-gradient(center, ellipse cover, rgba(231, 124, 52, 0.55) 0%, rgba(0, 0, 0, 0.6) 98%, rgba(0, 0, 0, 0.6) 100%);
            // /* FF3.6-15 */
            // background: -webkit-radial-gradient(center, ellipse cover, rgba(231, 124, 52, 0.55) 0%, rgba(0, 0, 0, 0.6) 98%, rgba(0, 0, 0, 0.6) 100%);
            // /* Chrome10-25,Safari5.1-6 */
            // background: radial-gradient(ellipse at center, rgba(231, 124, 52, 0.55) 0%, rgba(0, 0, 0, 0.6) 98%, rgba(0, 0, 0, 0.6) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &:before {
            @include overlay($color-black, 0, 1);
            @extend %transition-ease;
            height: 0;
        }
        img {
            transform: scale(1);
            @extend %transition-ease;
            width: 100%;
        }
    }
    h2 {
        font-size: $font-h2 + 2;
        color: $color-white;
        font-weight: 400;
        transition: all ease .25s;
    }

    .subtitle {
        margin: 15px 0 25px 0;
        font-size: 25px;
        color: $color-white;
        transition: all ease .25s;
    }

    &:hover {

        figure {
            overflow: hidden;
            &:after {
                @include overlay($color-black, .8, 1);
                height: 100%;
            }
            &:before {
                height: 100%;
            }
            img {
                transform: scale(1.2);
            }
        }
        h2 {
            color: $color-secondary;
            // transition-delay: .5s;
        }

        .subtitle {
             color: $color-secondary;
        }
        span {
            background: $color-white;
            // transition-delay: .5s;
            i {
                color: $color-secondary;
            }
        }
    }
    /**
     *
     * Team Block
     *
     */
    &-w-detail {
        figure {
            &:after {
                @include overlay($color-black, 0, 1);
            }
            img {
                filter: hue-rotate(-15deg) contrast(1) saturate(1.14);
                -webkit-filter: hue-rotate(-15deg) contrast(1) saturate(1.14);
            }
        }
        h3 {
            color: $color-secondary;
        }
    }
    /**
     *
     * Event Block
     *
     */
    &-w-bdr {
        figure {
            &:after {
                @include overlay($color-black, 0, 1);
            }
            img {
                filter: hue-rotate(-15deg) contrast(1) saturate(1.14);
                -webkit-filter: hue-rotate(-15deg) contrast(1) saturate(1.14);
            }
        }
        h3 {
            color: $color-secondary;
        }
        &:hover {
            figure {
                &:after,
                &:before {
                    display: none;
                }
            }
        }
    }

    // with ad tag
    &.ad {
        position: relative;

        &:before {
            pointer-events: none;
            text-align: center;
            color: $color-white;
            text-transform: uppercase;
            z-index: 10;
            content: 'publicité';
            position: absolute;
            width: 300px;
            padding: 10px 0;
            background: $color-primary;

            transform-origin: center center;
            transform: translate3d(-50%, 0, 0) rotate(45deg);
            left: calc(100% - 52px);
            top: 34px;
        }
    }

    // with new tag
    &.new {
        position: relative;

        &:before {
            pointer-events: none;
            text-align: center;
            color: $color-white;
            text-transform: uppercase;
            z-index: 10;
            content: 'nouveauté';
            position: absolute;
            width: 300px;
            padding: 10px 0;
            background: $color-secondary;

            transform-origin: center center;
            transform: translate3d(-50%, 0, 0) rotate(45deg);
            left: calc(100% - 52px);
            top: 34px;
        }
    }



}

.img-block-desc {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 15px;
    // Left Section
    &-left {
        width: calc(100% - 60px);
        float: left;
        border-right: 1px solid $color-lightblue;
        padding-right: 20px;
        h3 {
            margin-top: 0;
            margin-bottom: 5px;
        }
        // Image block with border bottom
        @at-root .img-block-w-bdr {
            border-bottom: 1px solid $color-lightblue;
            .img-block-desc {
                margin-top: 20px;
                margin-bottom: 20px
            }
            .img-block-desc-left {
                border-right: none;
                padding-left: 20px;
            }
        }
    }
    // Right Section
    &-right {
        margin-top: 0px;
        @include vertical-align(absolute, 50%);
        right: 10px;
        .icon-orange {
            padding-left: 0;
            i {}
        }
    }
}
