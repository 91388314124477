.breadcrumb-sec {
  clear: both;
  display: none;
  @extend %clearfix;
  margin-bottom: 10px;
  .return {

    display: block;
    margin-top: 3px;

    @include breakpoint(mobile) {
      float: left !important;
      border-top:1px solid #ddd;
      border-bottom:1px solid #ddd;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 10px;
      span {
        float: right;

      }
      .arrow-rotated {
        transform:rotate(0);
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.breadcrumb {
  background: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  padding-top: 0;
  @include breakpoint(mobile) {
    width: 100%;
  }
  li {
    font-size: 12px;
    color: $color-primary;
    font-weight: 500;
    margin-right: 5px;
    text-transform: uppercase;
    a {
      color: $color-primary;
      line-height: 20px;
      vertical-align: middle;

      @include hover-state {
        color: $color-secondary !important;
        text-decoration: none;
      }
     span {
      vertical-align: middle;
      line-height: 18px;

     }
     i {
      vertical-align: baseline;
     }
    }
    &:before {
      display: none;
    }
  }
}
