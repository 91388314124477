.banner {
  display: block;
  clear: both;
  position: relative;
  .banner-content {
    position: relative;
    img {
      width: 100%;
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      height: 100%;
      width: 100%;

      @extend %gradient-overlay-blue;
      width: 50%;
       @include breakpoint(tablet) {
      width: 25%;
    }
    }
  }
  &-caption {
    color: $color-primary;
    @include vertical-align(absolute, 50%);
    z-index: 2;
    left: 5%;
    min-height: 88%;
    min-width: 90%;
    background: $color-white;
    text-align: left;
    @include breakpoint(tablet) {
      min-height: 300px;
      min-width: 320px;
 	left: 5%;
    }
    @include breakpoint(md) {
		left: 15%;
      min-height: 350px;
      min-width: 350px;
    }
    @include breakpoint(lg) {
      min-height: 370px;
      min-width: 370px;
    }
    @include breakpoint(xlg) {
      min-height: 400px;
      min-width: 420px;
    }
    span {
      font-size: $font-size-body + 3px;
      color: $color-secondary;
      @include breakpoint(md){
      	font-size: $font-size-body + 8px;
      }
    }
    h1 {
      text-transform: uppercase;
      font-weight: 900;
      margin-top: 10px;
    }
    &-detail {
      @include vertical-align(absolute, 50%);
      padding-left: 40px;
      padding-right: 40px;
      text-align: left;
      left: 0;
    }
  }

  // fix wayyyy to big h1 in mobile
  @media screen and (max-width: 768px) {
    .banner-content {
      .banner-caption {
        .banner-caption-detail {
          h1 {
            font-size: calc(15px + 2vw);
            line-height: calc(17px + 2vw);
          }
        }
      }
    }
  }

}

.slick-arrow {
  &.slick-prev,
  &.slick-next {
    display: none !important;
  }
}
