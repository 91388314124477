.footer-top {
	background: $color-lightblue;
	display: block;
	position: relative;
	overflow: hidden;
	@extend %clearfix;
	&-left {
		float: left;
		width: 30%;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		.mapWrapper {
			padding-bottom:0;
			height: 100%;
		}
	}
	&-right {
		float: right;
		width: 100%;
		padding: 10px 0 40px 0px;
		padding-bottom: 40px;
		[class^="col"] {
			@include breakpoint(mobile) {
				padding: 0;
			}
			@include breakpoint(tablet) {
				padding: 0;
			}
			@include breakpoint(md) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		.text-block {
			@include breakpoint(mobile) {
				padding: 0;
			}

		}
		@include breakpoint(tablet) {
			width: 100%;
			padding: 40px 0 70px 0px;
		}
		@include breakpoint(md) {
			width: 70%;
			padding: 40px 0 70px 50px;
		}
		@include breakpoint(xlg) {
			width: 75%;
			padding: 70px 0 70px 50px;
		}
		strong {
			display: block;
		}
		p {
			padding-bottom: 15px;
		}
		p + p {
			margin-bottom: 20px;
			@include breakpoint(mobile) {
				margin-bottom: 10px;
			}
		}
	}
}

.footer-btm {
	padding: 30px 0;
	@include breakpoint(tablet-only) {
		padding: 20px 0;
	}
	@include breakpoint(mobile) {
		text-align: center;
		padding: 15px 0;
	}
	padding: 30px 0;
	.partner {
		text-align: center;
		display: inline-block;
		margin-top: 10px;
		margin-bottom: 10px;
		@include breakpoint(mobile) {
			display: block;
			margin-left: 0%;
			margin-top: 30px;
			clear: both;
			overflow: hidden;
		}
		@include breakpoint(tablet-only) {
			width: 100%;
			margin-left: 0%;
		}
		@include breakpoint(md) {
			margin-left: 2%;
		}
		@include breakpoint(lg) {
			margin-left: 3%;
		}
		@include breakpoint(xlg) {
			margin-left: 10%;
		}
		img {
			margin: 0 30px;
			@include breakpoint(tablet-only) {
				margin: 0 20px;
			}
			@include breakpoint(medium-only) {
				margin: 0 10px;
				height: 28px;
			}
			@include breakpoint(lg) {
				margin: 0 20px;
			}
			@include breakpoint(mobile) {
				display: block;
				margin: 30px auto;
			}
		}
	}
	.pull-left,
	.pull-right {
		float: none;
		text-align: center;
		width: 100%;
		@include breakpoint(tablet-only) {
			width: 100%;
		}
		@include breakpoint(md) {
			float: left;
			width: auto;
		}
	}
	.pull-right {
		@include breakpoint(md) {
			float: right;
		}
	}
}

.timing {
	margin-top: 40px;
	p {
		float: left;
		&:last-of-type {
			float: right;
		}
	}
}


.TimeWrapper {
	.Time {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	// 480px +
	@media screen and (min-width: 480px) {
		.Time {
			.Time_place {
				margin-bottom: 0;
				margin-top: 30px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					width: 50%;
					padding: 10px 15px 0 0;
					font-size: 14px;
					color: #333333;
					font-weight: 600;
					line-height: 16px;

					.day {
						margin-bottom: 3px;
					}

					.hours {
						font-weight: 300;
					}
				}
			}
		}
	}

	// 768px +
	@media screen and (min-width: 768px) {
		.Time {
			ul {
				li {
					width: 100%;
				}
			}
		}
	}

	// 1505px +
	@media screen and (min-width: 1505px) {
		.Time {
			ul {
				li {
					width: 50%;
				}
			}
		}
	}
}






















