.search {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    border-right: 1px solid $color-lightblue;
    padding: 12px 20px;
    @include breakpoint(tablet) {
        padding-right: 0;
        border-right: none;
        padding-left: 0;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid $color-lightblue;
        padding:10px 20px 10px 0;

    }
     @include breakpoint(md) {
        border:none;
        padding-right: 0!important;
        margin-right: 0!important;
     }
    .close-icon {
        display: none;
    }
    &.searching {
        input {
            width: 90%;
            opacity: 1;
            @include breakpoint (tablet) {
                width: 458px;
            }
        }
        .search-icon {
            display: none;
        }
        .close-icon {
            display: block;

            font-size: $font-h2 + 10px;
            @include breakpoint (tablet) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
            @include breakpoint (md) {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }
    }

    .FormSeach {
        position: absolute;
        top: calc(100% - 3px);
        left: 0;
        z-index: 11;
        width: 100%;
        background: $color-lightblue;
        padding: 22px 15px;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: all ease-in .25s;
        &:before {
            content: '';
            position: absolute;
            top: calc(100%);
            left: 0;
            right: 0;
            height: 15px;
            background: linear-gradient(0deg, rgba(2, 83, 126, 0) 0%, rgba(2, 83, 126, 0.15) 100%);
        }

        .FormElement {
            input {
                display: block;
                width: 100%;
                padding: 5px 15px;
                -webkit-appearance: none;
                line-height: 40px;
                font-size: 14px;
                border: none;
                outline: none;
                &:focus {
                    + .line {
                        border-top: 1px solid rgba($color-darkblue, 1);
                    }
                }
            }

            .line {
                margin: 0;
                border-top: 1px solid rgba($color-darkblue, .1);
                transition: all ease-in .45s;
            }
        }

        &.is-searching {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }

        @media screen and (min-width: 1510px) {
            right: 0;
            left: auto;
            top: 100%;
            width: 74.3vw;
            z-index: 5;
        }
    }
}
