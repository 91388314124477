


h1 {
	font-size: $font-h1 - 18px;
	font-family: $font-site;
	@include breakpoint(tablet){
		font-size: $font-h1 - 16px;
	}
	@include breakpoint(md){
		font-size: $font-h1 - 14px;
	}

	@include breakpoint(xlg){
		font-size: $font-h1;
	}

}
h2 {
	font-size: $font-h2;
	font-family: $font-site;
}

h3 {
	font-size: $font-h3 - 3px;
	font-family: $font-site;
	@include breakpoint(tablet){
		font-size:  $font-h3 - 2px;
	}
	@include breakpoint(md){
	font-size:  $font-h3;
	}

}

p {
	font-size: $font-size-body - 1px;
	line-height: 20px;
	@include breakpoint(tablet){
		font-size: $font-size-body;
	}
	.small {
		font-size: $font-size-body - 2px;
	}
}

.heading-sec {
	padding:20px 0;
	@extend %clearfix;
	h2 {
		font-weight: 900;
	}
	img {
		margin-right: 8px;
		display: inline-block;
		vertical-align: middle;
		@include breakpoint(tablet){
			margin-right: 20px;
		}

	}
}

// fix de pauvre yes

.MainNav ul li a {
	text-decoration: none;
}

.breadcrumb-sec {
	a  {
		text-decoration: none!important;
	}
}

a {
	text-decoration: none;

	&:not([class]) {
		color: $color-secondary;
		font-weight: bold;
		text-decoration: underline;
	}
}