// Social Icon
.social-icon {
  a {
    color: $color-white;
    padding: 8px 9px;
    font-size: $font-size-body + 2px;
    float: left;
    border-right: 1px solid $color-primary-light;
    display: inline-block;
    @include breakpoint(tablet) {
      padding: 9px 15px;
    }
    @include breakpoint(md) {
      padding: 10px 15px;
    }
    @extend %transition;
    &:last-child {
      border-right: none;
    }
    @include hover-state {
      color: $color-secondary;
      background: rgba($color-white, 0.7);
    }
    i {
      font-size: $font-h2 - 6;
      @include breakpoint(tablet) {
        font-size: $font-h2;
      }
    }
  }
  &.vertical {
    a {
      color: $color-primary;
      border: none;
      margin-top: 15px;
      margin-bottom: 15px;
      position: relative;
      i {
        font-size: $font-h2 + 2;
      }
      &:hover {
        background: none;
        color: $color-secondary;
      }
      &:after {
        @include overlay($color-lighterblue-bdr, 1, 1);
        width: 1px;
        height: 20px;
        top: 45px;
        left: 2px;
        right: 0;
        margin: auto;
        bottom: auto;
      }
      &:first-of-type {
        i {
          padding-left: 6px;
        }
        &:after {
          left: 7px;
        }
      }
      &:nth-child(2) {
        i {
          padding-left: 3px;
          margin-left: -2px;
        }
      }
      &:last-child {
        i {
          padding-left: 3px;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
