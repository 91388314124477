// Font Variables
$font-h1: 48px !default;
$font-h2: 22px !default;
$font-h3: 21px !default;
$font-h4: 18px !default;
$font-h5: 13px !default;
$font-size-body: 16px !default;

// Colors
$color-black : #000 !default;
$color-black-light : #333 !default;

$color-white : #fff !default;


$color-skyblue: #00ADE7 !default;
$color-lightblue: #E5EDF2 !default;
$color-darkblue: #01537E !default;

$color-lighterblue: #CDDCE6 !default;
$color-lighterblue-bdr: #3A82A6 !default;
$color-green:#63992E !default;

$color-primary: #02537E !default;

$color-primary-75 :rgba($color-primary, .75) !default;
$color-primary-50 :rgba($color-primary, .5) !default;
$color-primary-light : lighten($color-primary, 5%) !default;
$color-primary-lighter:ighten($color-primary, 10%) !default;

$color-secondary: #D96D20 !default;



$color-body : $color-black-light!default;


// Fonts
$font-site: 'Libre Franklin', sans-serif !default;


// Container
$width-container: 1480px !default;
$width-container-fluid: 100% !default;


$ease-in : cubic-bezier(0.430, 0.045, 0.100, 0.950);



