// ----------------------------------
// MainNav

.FiltersWrapper {
	display: flex;
	flex-wrap: wrap;

	a {
		color: #02537E;
		font-size: 16px;
		line-height: 16px;
		display: inline-block;
		padding: 16px!important;
		background: $color-white;
		border: 1px solid #81a8bd;
		transition: all ease-in .2s!important;
		cursor: pointer;
		& {
			margin-right: 10px;
			margin-bottom: 10px;
		}

		&:hover {
			background: $color-secondary;
			color: $color-white;
		}
	}
}

.MainNav {
	display: none;
	vertical-align: middle;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		> li {
			margin: 0;
			padding: 0;
			display: inline-block;
			vertical-align: middle;
			&:last-child {
				margin-right: 10px;
			}

			> a {
				color: #02537E;
				font-size: 14px;
				line-height: 60px;
				text-transform: uppercase;
				font-weight: normal;
				display: block;
				height: 100%;
				padding-left: 10px;
				padding-right: 10px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					right: -2px;
					top: 50%;
					height: 60px;
					width: 1px;
					background: #e6eef2;
					transform: translate3d(0, -50%, 0);
				}

				&:after {
					content: '';
					position: absolute;
					bottom: -2px;
					left: 50%;
					transform: translate3d(-50%, 10px, 0);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 22px 22px 22px;
					border-color: transparent transparent #E5EDF2 transparent;
					opacity: 0;
					transition: all ease-in .3s .1s;
				}

				svg {
					position: absolute;
					bottom: 15px;
					left: 50%;
					transform: translate3d(-50%, 0, 0);
					opacity: 1;
					transition: all ease-in .2s;
				}
			}
			
			.MainNav_sub {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				transition: all ease-in .3s;
				// top: 133px;
				left: 0;
				right: 0;
				z-index: 10;
				background: $color-lightblue;
				width: 100%;
				&:after {
					content: '';
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;
					height: 20px;
					background: linear-gradient(0deg, rgba(2,83,126,0) 0%, rgba(2,83,126,0.4) 100%);
				}

				.wrapper {
					display: flex;
					justify-content: space-between;
					align-items: center;
					overflow: hidden;

					ul {
						margin: 0;
						padding: 75px 15px;
						list-style: none;
						display: flex;
						flex-wrap: wrap;
						max-width: 740px;
						margin: 0 auto;

						li {
							display: flex;
							// align-items: center;
							width: calc(100% / 3);
							&:last-child {
								margin-right: 0;
							}

							i {
								margin-right: 5px;
								margin-top: 7px;
								// align-self: center;
							}

							a {
								padding: 10px 10px 10px 0;
								font-size: 18px;
								line-height: 20px;
								text-transform: none;
								&:after {
									display: none;
								}
							}
						}
					}

					.MainNav_subFilter {
						position: relative;
						width: 40%;
						height: 100%;
						&:before {
							content: '';
							position: absolute;
							top: -1000px;
							left: 0;
							height: 2000px;
							width: 100%;
							background: #cddce6;
						}

						.content {
							position: relative; 
							z-index: 1;
							padding: 75px 25px;

							p {
								color: #02537E;
								font-size: 18px;
								line-height: 21px;
								font-weight: bold;
								margin-bottom: 25px;
							}
						}
					}
				}

			}

			// :HOVER
			&:hover {
				cursor: pointer;
				> a {
					color: $color-secondary;
					&:after {
						transform: translate3d(-50%, 0, 0);
						opacity: 1;
					}
					svg {
						opacity: 0;
						transform: translate3d(-50%, 10px, 0);
					}
				}

				// _sub
				.MainNav_sub {
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}
			}
		}
	}

	@include breakpoint(tablet) {
		ul {
			> li {
				> a {
					padding-top: 35px;
					padding-bottom: 35px;
				}
			}
		}
	}

	@include breakpoint(md) {
		display: inline-block;

		ul {
			> li {
				a {
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
	}

	@include breakpoint(xlg) {
		ul {
			> li {
				> a {
					font-size: 18px;
					padding-right: 40px;
					padding-left: 40px;
					padding-top: 30px;
					padding-bottom: 30px;

					svg {
						bottom: 35px;
					}
				}

				// .MainNav_sub {
				// 	top: 173px;
				// }
			}
		}
	}
}

// ----------------------------------
// MainNav

.MobileNav {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all ease-in .3s;
	&.is-active {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;

		ul {
			li {
				&.has-child {
					ul {
						li {
							a {
								font-weight: normal;
							}
						}
					}
				}
			}
		}
	}

	ul {
		margin: 0;
		padding: 20px 0;
		list-style: none;
		background: #02537E;

		li {
			margin: 0;
			padding: 0;
			position: relative;
			// has sousmene

			> a {
				display: block;
				width: 100%;
				border-bottom: 1px solid rgba($color-white, .2);
			}
			&.has-child {
				svg {
					display: block;
					height: 15px;
					width: 15px;
					position: absolute;
					top: 31px;
					right: 25px;
					z-index: 10;
					transform-origin: center center;
					transform: rotate(0);
					transition: all ease-in .3s;
					polygon {
						fill: $color-secondary;
						transition: all ease-in .3s;
					}
				}

				ul {
					display: none;
					margin: 0;
					padding: 28px 0;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: -25px;
						right: -25px;
						bottom: 0;
						background: #E5EDF2;
					}

					li {
						margin: 0;
						padding: 0 25px;
						
						i {
							display: inline-block;
							vertical-align: middle;
						}

						a {
							color: #02537E;
							font-size: 18px;
							line-height: 20px;
							padding: 6px 0;
							display: inline-block;
							width: auto;
							vertical-align: middle;
							border-bottom: none;
							text-transform: none;
						}
					}
				}

				// when is-open
				&.is-open {
					> a {
						color: #02537E;
						font-weight: bold;
						background: #E5EDF2;
						border-bottom: 1px solid #CDDCE6;
					}
					svg {
						transform: rotate(180deg);
						polygon {
							fill: $color-darkblue;
						}
					}
				}
			}

			> a {
				color: $color-white;
				font-size: 18px;
				line-height: 14px;
				padding: 30px 25px;
				text-transform: uppercase;
			}
		}
	}

	@media screen and (min-width: 768px) {
		// top: 133px;
	}

	@include breakpoint(md) {
		display: none;
	}
}





// ----------------------------------
// ----------------------------------
// ----------------------------------
// ----------------------------------
// ----------------------------------
// ----------------------------------
// ----------------------------------
// Mobile Menu Toggle
.menu-toggle {
	display: inline-block !important;
	vertical-align: middle;
	margin-top: 0px;
	padding-top: 21px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 20px;
	border-left: none;
	float: right;
	@include breakpoint(tablet) {
		padding-top: 10px;
		float: none;
		margin-top: 0px;
		padding-bottom: 10px;
		margin-left: 0;
	}
	@include breakpoint(md) {
		display: none !important;
	}
	p {
		margin-bottom: 0;
		padding-right: 10px;
		color: $color-primary;
	}
// Mobile Menu Text
.menu-text {
	display: none;
	vertical-align: middle;
	font-size: $font-size-body + 2px;
	@include breakpoint(tablet) {
		display: inline-block;
	}
}
// Mobile Menu Toggle Button
.toggle-button {
	display: inline-block;
	vertical-align: middle;
	@include breakpoint(tablet) {
		margin-top: 4px;
	}
	span {
		width: 24px;
		height: 2px;
		background: $color-secondary;
		margin-bottom: 4px;
		display: block;
	}
}
&.toggle-active {
	background: $color-secondary;
	span {
		background: $color-white;
		&:nth-child(2) {
			opacity: 0;
			visibility: hidden;
		}
		&:nth-child(1) {
			transform: rotate(45deg);
			position: relative;
			top: 6px;
		}
		&:nth-child(3) {
			transform: rotate(-45deg);
			position: relative;
			top: -6px;
		}
	}
	.menu-text {
		color: $color-white;
	}
}
}

.navbar-toggle {
	display: none;
}

.main-menu {
	display: none;
	margin-right: 0px;
	border-top: 1px solid $color-lightblue;
	@include breakpoint(md) {
		margin-right: 25px;
	}
	@include breakpoint(md) {
		display: inline-block;
		border-top: none;
	}
	@include breakpoint(xlg) {
		margin-right: 20px;
	}
	> ul {
		> li {
			display: inline-block;
			vertical-align: top;
			text-transform: uppercase;
			text-align: center;
			min-height: 63px;
			border-right: 1px solid $color-lightblue;
			font-size: $font-size-body;
			@include breakpoint(lg) {
				font-size: $font-h4;
			}
			> a {
				padding: 15px 15px 0;
				@include breakpoint(tablet) {
					padding: 15px 20px 0;
				}
				@include breakpoint(xlg) {
					padding: 20px 35px 0px;
				}
				display: block;
				color: $color-primary;
				i {
					display: block;
					color: $color-secondary;
					margin-top: 5px;
					font-size: $font-h4;
				}
				&:hover,
				&:focus {
					background: none !important;
				}
			}
			&:hover {
				>a {
					color: $color-secondary;
					i {
						color: $color-primary;
					}
				}
			}
			&.active {
				> a {
					color: $color-primary;
					font-weight: bold;
				}
			}
		}
	}
}

// Mega Menu
.mega-dropdown {
	position: static !important;
	overflow: hidden;
	&-left {
		width: 70%;
		padding: 80px 40px;
		padding-left: 0%;
		@include breakpoint(medium-only) {
			width: 100%;
			padding: 40px 40px;
		}
		@include breakpoint(xlg) {
			padding-left: 10%;
		}
		li {
			list-style: none;
			text-transform: none;
			vertical-align: middle;
			padding-bottom: 15px;
			font-size: $font-h4;
			display: inline-block;
			width: 30%;
			margin-right: 3%;
			@include breakpoint(xlg) {
				width: 25%;
			}
			a {
				vertical-align: middle;
				display: block;
				color: $color-primary;
				i {
					vertical-align: middle;
					margin-right: 10px;
				}
				&:hover {
					color: $color-secondary;
				}
			}
		}
	}
	&-right {
		width: 30%;
		@include breakpoint(medium-only) {
			width: 100%;
			padding: 40px 40px;
		}
	}
}

.mega-dropdown-menu {
	padding: 0;
	width: 100%;
	background: $color-lightblue;
	overflow: hidden;
	box-shadow: 0px 10px 15px rgba($color-primary, 0.5);
	> li {
		> ul {
			padding: 0;
			margin: 0;
		}
	}
}

// Mobile Menu
.mobile-menu {
	float: left;
	width: 100%;
	display: block;
	@include breakpoint(tablet) {
		position: absolute;
		left: 0;
		background: $color-white;
		top: 100%;
		z-index: 2;
		width: 100%;
	}
	@include breakpoint(md) {
		display: none;
	}
	 ul {
		padding-top: 30px;
		padding-bottom: 100px;
		background: $color-primary;
		margin-bottom: 0;
		.drop-menu {
			color: $color-white;
			background: $color-primary;
			padding: 0px 40px;
			position: relative;
			@extend %transition;
			a {
				display: block;
				color: $color-white;
				text-transform: uppercase;
				padding: 20px 0px;
				border-bottom: 1px solid rgba($color-white, .2);
			}
			&.open {
				background: $color-lightblue;
				color: $color-primary;
				>a {
					font-weight: 900;
					color: $color-primary;
					border-bottom-color: $color-lighterblue;
				}
				.down-arrow {
					color: $color-primary;
					transform: rotate(180deg);
				}
			}
		}
	 // SubMenu
	 padding-left: 0;
	 .MainNav_sub{
	 	ul {
		 	display: none;
		 	padding-left: 0;
		 	list-style: none;
		 	padding: 20px 0;
		 	li {
		 		padding-bottom: 12px;
		 		a {
		 			color: $color-primary;
		 			@extend %transition;
		 			&:hover {
		 				color: $color-secondary;
		 				i {
		 					color: currentcolor;
		 				}
		 			}
		 			i {
		 				vertical-align: middle;
		 				padding-right: 10px;
		 			}
		 		}
		 	}
	 	}
	 }
	}
	// Down button
	.down-arrow {
		position: absolute;
		right: 40px;
		top: 0px;
		color: $color-secondary;
		cursor: pointer;
		padding: 16px 5px;
	}
}
