.btn {
  padding: 12px 20px;
  display: inline-block;
  color: $color-white;
  font-size: $font-size-body;
  border: none;
  line-height: 1;
  border-radius: 0;
  @extend %transition;
  // Btn Default
  &-default {
    border: 1px solid $color-primary;
    color: $color-primary;
  }
  // Btn Secondary
  &-secondary {
    background: $color-secondary;
    font-weight: 600;
    padding: 10px 20px;
    @include hover-state {
      color: $color-secondary;
      background: rgba($color-white, 0.7);
    }
  }
  &-white {
    background: $color-white;
    font-weight: 600;
    padding: 15px 20px;
    color: $color-primary;
    border: 1px solid rgba($color-darkblue, .5);
    text-transform: none;
    margin-bottom: 7px;
    margin-right: 7px;
    @include hover-state {
      background: $color-secondary;
      color: $color-white;
      border: 1px solid $color-secondary;
    }
  }
  // Btn With Icon
  &-w-icon {
    font-size: $font-size-body - 2px;
    border: 1px solid $color-primary;
     @include breakpoint(lg){
    font-size: $font-size-body;
  }
    padding: 0 0 0 15px;
    i {
      border-left: 1px solid $color-primary;
      margin-left: 8px;
      padding: 7px 8px;
      vertical-align: middle;
      @include breakpoint(md){
        padding: 7px 13px;
      }
    }
    &:hover {
      border-color: $color-white;
      background: $color-primary;
      color: $color-white;
    }
    &.blue {
      color: $color-primary;
      &:hover {
        border-color: $color-white;
        background: $color-primary;
        color: $color-white;
        i {
          border-left-color: $color-white;
        }
      }
    }
    &.blue-light {
      color: $color-lightblue;
      border: 1px solid $color-lightblue;
      i {
        border-left-color: $color-lightblue;
      }
      &:hover {
        border-color: $color-primary;
        background: $color-primary;
        color: $color-white;
        i {
          border-left-color: $color-lightblue;
        }
      }
    }
    &.white {
      color: $color-white;
      border: 1px solid $color-white;
      i {
        border-left-color: $color-white;
      }
      &:hover {
        border-color: $color-primary;
        background: $color-primary;
        color: $color-white;
        i {
          border-left-color: $color-white;
        }
      }
    }
  }
}

// Button Group
.btn-group {
  padding: 20px;
  // Btn Group With Background
  &-w-bg {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: 20px 30px;
    @include breakpoint(tablet) {
      width: 100%;
      text-align: center;
      background: none;
    }
    @include breakpoint(md) {
      text-align: left;
    }
    .btn {
      color: $color-primary;
      font-weight: 500;
      &:hover {
        border-color: $color-white;
        background: $color-primary;
        color: $color-white;
        i {
          border-color: $color-white;
        }
      }
      &.blue-bg {
        background: $color-primary;
        color: $color-white;
        i {
          border-left: 1px solid $color-lighterblue;
        }
      }
      @include breakpoint(tablet) {
        float: none;
      }
      @include breakpoint(md) {
        float: left;
      }
    }
    &:after {
      @include overlay($color-lightblue, 1, -1);
      display: none;
      @include breakpoint(md) {
        width: 84%;
        display: block;
      }
    }
  }
  &-center {
    padding: 30px 20px;
    text-align: center;
    width: 100%;
    background: $color-lighterblue;
    a {
      float: none;
      &.btn {
        float: none;
      }
    }
  }
}
