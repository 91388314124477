/**
 *
 * Rounded box with Icon
 *
 */

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  padding-left: 2px;
  padding-top: 1px;
  border-radius: 50%;
  border: 1px solid $color-white;
  @extend %transition;
  @include breakpoint(tablet) {
    width: 40px;
    height: 40px;
    padding-left: 7px;
    padding-top: 6px;
  }
  i {
    color: $color-white;
    @extend %transition;
  }
  &:hover {
    background: $color-white;
    i {
      color: $color-secondary;
    }
  }
  // Icon Orange
  &-orange {
    border: 1px solid $color-primary-75;
    @include breakpoint(tablet) {
      border: 1px solid $color-primary-50;
    }
    i {
      color: $color-secondary;
    }
    &:hover {
      background: $color-secondary;
      border-color: $color-secondary;
      i {
        color: $color-white;
      }
    }
  }
  // Icon blue
  &-blue {
    border: 1px solid $color-lightblue;
    i {
      color: rgba($color-darkblue, .5);
    }
    &:hover {
      background: $color-darkblue;
      border-color: $color-darkblue;
      i {
        color: $color-white;
      }
    }
  }
  // Icon white
  &-white {
    padding-left: 0;
  }
  // Icon Small
  &-small {
    width: 30px;
    height: 30px;
    padding-left: 0px;
    padding-top: 3px;
    i {
      font-size: 20px;
    }
    &.icon-white {
      padding-top: 0;
    }
  }
  &-w-blue-bg {
    background: $color-primary;
    border-color: $color-primary;
    i {}
  }
}

.material-icons {
  &:before {
    content: attr(data-icon);
    font-family: 'Material Icons'!important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: normal;
    font-feature-settings: 'liga';
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


/**
 *
 * Arrow
 *
 */

.arrow {
  display: inline-block;
  &-default {
    i {
      color: $color-primary;
    }
  }
  i {
    vertical-align: middle;
  }
  // Orange Color
  &-orange {
    i {
      color: $color-secondary;
    }
  }
  // Rotated arrow: ;
  &-rotated {
    @include translate(-180deg, rot);
  }
}
