body {
  line-height: 1;
  font-size: $font-size-body;
  color: $color-body;
  font-family: $font-site;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  @extend %transition;
  color: $color-body;
  display: inline-block;
  @include hover-state {
    color: $color-secondary;
    text-decoration: none;
  }
  &.color-primary {
    @include hover-state {
      color: $color-secondary !important;
      text-decoration: none;
    }
  }
}


/**
 *
 * Font Weight
 *
 */

.font-bold {
  font-weight: 600;
}


/**
 *
 * Font size
 *
 */

.fs-18 {
  font-size: 18px !important;
  @include breakpoint(mobile){
    font-size: 16px !important;
  }
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: $color-primary;
}

img {
  max-width: 100%;
  height: auto;
}

address {
  font-size: 14px;
  margin-bottom: 10px;
}

.block {
  display: block !important;
  clear: both;
}

.align-center {
  @extend .block;
  margin: 0 auto;
}


/**
 *
 * Container
 *
 */

.container {
  width: $width-container-fluid;
  margin: 0 auto;
  padding: 0 20px;
  @include breakpoint(xlg) {
    width: $width-container;
  }
  @include breakpoint(tablet){
    padding: 0 50px;
  }
  @include breakpoint(md){
    padding: 0 15px;
  }
  >.navbar-header {
    margin-left: 0 !important;
    margin-right: 0!important;
  }
}

.container-fluid {
  width: $width-container-fluid;
  margin: 0 auto;
}

.row {
     margin-left: 0 ;
    margin-right: 0 ;
  @include breakpoint(xlg) {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}


/**
 *
 * Width
 *
 */

.width70 {
  width: 65%;
}
.width65 {
  width: 65%;
}


/**
 *
 * Alignment
 *
 */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}


/**
 *
 * Float
 *
 */

.float-left {
  float: left;
}

.float-right {
  float: right;
}


/**
 *
 * Background
 *
 */

.bg-white {
  background: $color-white !important;
}

.bg-secondary {
  background: $color-secondary !important;
}

.bg-green {
  background: $color-green !important;
}

.bg-dark-blue {
  background: $color-darkblue !important;
}


/**
 *
 * Color
 *
 */

.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

.color-green {
  color: $color-green !important;
}

.color-dark-blue {
  color: $color-darkblue !important;
}


/**
 *
 * Border
 *
 */

.bdr-lr-none {
  border-left: none !important;
  border-right: none!important;
}


/**
 *
 * Visibility
 *
 */


// Mobile
.hidden-mobile {
  display: none !important;
  @include breakpoint(tablet) {
    display: block !important;
  }
}

.visible-mobile {
  display: block !important;
  @include breakpoint(tablet) {
    display: none !important;
  }
}
.hidden-tablet {
  display: none !important;
  @include breakpoint(tablet) {
    display: none !important;
  }
  @include breakpoint(md) {
    display: block !important;
  }
  @include breakpoint(lg) {
    display: block !important;
  }

}

.visible-tablet {
  display: none !important;
  @include breakpoint(tablet) {
    display: block !important;
  }
  @include breakpoint(md) {
    display: none !important;
  }
   @include breakpoint(lg) {
    display: none !important;
  }
   @include breakpoint(xlg) {
    display: none !important;
  }
}

// Desktop
.hidden-md {
  display: block !important;
  @include breakpoint(md) {
    display: none !important;
  }
}

.visible-md {
  display: none !important;
  @include breakpoint(md) {
    display: block !important;
  }
}
