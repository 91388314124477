.mar-btm0 {
	margin-bottom: 0px;
}
.mar-btm10 {
	margin-bottom: 10px;
}
.mar-btm20 {
	margin-bottom: 20px;
}
.mar-btm30 {
	margin-bottom: 30px;
}
.mar-btm40 {
	margin-bottom: 40px;
}
.mar-btm60 {
	margin-bottom: 60px;
}
.mar-btm80 {
	margin-bottom: 80px;
}


.mar-lt50 {
	margin-left: 50px;
}
.mar-t50 {
	margin-top: 50px;
}
.mar-t10 {
	margin-top: 10px;
}
.mar-t20 {
	margin-top: 20px;
}
.mar-t30 {
	margin-top: 30px;
}
.mar-t40 {
	margin-top: 40px;
}
.mar-t100 {
	margin-top: 100px;
}
.mar-t200 {
	margin-top: 200px;
}



.padd-rt0 {
	padding-right: 10px;
}
.padd-btm10 {
	padding-bottom: 10px;
}

.padd-top15 {
	padding-top: 15px;
}

.bdr-top {
	border-top:1px solid $color-lightblue;
}