/**

    Panel:
    - Panel is used for HOMEPAGE on event section.
    - Panel Primary is used on Program Detail Page.

 */

.panel {
  width: 100%;
  float: left;
  margin-right: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  // Panel Heading
  &-heading {
    color: $color-white;
    padding: 25px 20px;
    border-radius: 0;
    margin: 0;
    font-weight: 900;
    line-height: 38px;
    text-transform: uppercase;
    @include breakpoint(mobile) {
      padding: 18px 25px;
    }
    @include breakpoint(tablet) {
      padding: 18px 25px;
    }
    img {
      margin-right: 15px;
      height: 37px;
      margin-top: -6px;
    }
  }
  // Panel Body
  &-body {
    border-radius: 0;
    padding: 0px;
    background: $color-white;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style: none;
        padding: 20px;
        position: relative;
        overflow: hidden;
        @include breakpoint(tablet) {
          padding: 20px 25px;
        }
        @extend %clearfix;

        &:after {
          @include overlay($color-lightblue, 1, 1);
          width: 150%;
          height: 1px;
          top: auto;
          @extend %cubic-transition-5s;
          transition-delay: 0.25s;
        }
        &:before {
          @include overlay($color-lightblue, 1, 1);
          width: 0;
          height: 1px;
          top: auto;
          left: 0;
          right: auto;
          @extend %cubic-transition-5s;
        }
        &:hover {
          &:after {
            width: 0%;
            transition-delay: 0.25s;
          }
          &:before {
            background: $color-primary;
            width: 200%;
          }
        }
      }
    }
    h3 {
      margin-top: 10px;
      margin-bottom: 0;
      color: $color-secondary;
    }
    span {
      text-transform: uppercase;
      font-size: 13px;
      color: $color-primary;
      font-weight: 500;
    }
    @include breakpoint(tablet){
      display: block !important;

    }
  }
  // Left Section
  &-item-left {
    width: calc(100% - 45px);
    float: left;
    border-right: 1px solid $color-lightblue;
    padding-right: 20px;
    @at-root .wo-bdr & {
      border-right: none;
    }
  }
  // Right Section
  &-item-right {
    float: right;
    margin-top: 0px;
    @include vertical-align(absolute, 50%);
    right: 0;
    display: block;
  }
  // Events
  &.events {
    h3 {
      color: $color-green;
    }
  }
  &.life-centre {
    .panel-heading {
      margin-bottom: 10px;
    }
    .panel-body {
      background: $color-lightblue;
      &-detail {
        padding: 10px 25px 40px;
        @include breakpoint(tablet) {
          float: left;
          width: 50%;
        }
        @include breakpoint(md) {
          float: left;
          width: 100%;
        }
        h2 {
          font-size: $font-h2 + 2;
        }
        span {
          text-transform: none;
          padding-bottom: 20px;
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 20px;
        }
      }
      figure {
        @include breakpoint(tablet-only) {
          float: left;
          width: 50%;
        }
        .desktop-img {
          display: block;
          @include breakpoint(tablet) {
            display: none;
          }
          @include breakpoint(md) {
            display: block;
          }
        }
        .tablet-img {
          display: none;
          @include breakpoint(tablet) {
            display: block;
          }
          @include breakpoint(md) {
            display: none;
          }
        }
        img {
          width: 100%;
        }
      }
    }
    h3 {
      color: $color-green;
    }
  }
  .panel-item {
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
  }
  // Panel Primary
  &-primary {
    .btn-group-w-bg {
      &:after {
        background: none;
      }
    }
    .panel-body {
      padding: 10px 20px;
      border: 1px solid $color-lighterblue;
      li {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
    .panel-item-left {
      width: 100%;
      border-right: none;
      padding-left: 0;
      padding-right: 0;
      h3 {
        font-size: $font-size-body;
        margin-top: 5px;
        margin-bottom: 5px;
        a {
          color: $color-primary;
          &:hover {
            color: $color-secondary;
          }
        }
      }
      small {
        font-size: $font-h5 - 1px;
        color: $color-primary-75;
      }
    }
    span {
      color: $color-secondary;
    }
  }
}
