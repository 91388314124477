// ----------------------------------
// GlobalMessage

.GlobalMessage {
	text-align: center;
	padding: 30px 15px 15px 15px;
	background: darken($color-secondary, 3%);
	position: relative;

	.GlobalMessage_text {
		color: $color-white;
		font-family: $font-site;
		font-size: calc(8px + 2vw);
		line-height: calc(11px + 2vw);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.text {
			display: inline-block;
			vertical-align: middle;
			padding: 0 15px;
		}

		.GlobalMessage_icon {
			display: inline-block;
			vertical-align: middle;
			line-height: 0;
			$svgSize : 35px;
			width: $svgSize;
			svg {
				max-width: 100%;
				width: 100%;
				line-height: 0;
				height: 30px;

				path {
					fill: $color-white;
				}
			}
		}
	}


	.GlobalMessage_close {
		position: absolute;
		top: 15px;
		right: 12px;
		transform: translate3d(0, -50%, 0) scale(.7);
		width: 30px;
		height: 30px;
		border: none;
		background: $color-white;
		border-radius: 50%;
		outline: none;

		&:before, &:after {
			content: '';
			position: absolute;
			width: 20px;
			height: 2px;
			top: 50%;
			left: 50%;
			background: $color-secondary;
		}

		&:before {
			transform: translate3d(-50%, -50%, 0) rotate(-135deg);
		}

		&:after {
			transform: translate3d(-50%, -50%, 0) rotate(-45deg);
		}
	}

	@media screen and (min-width: 560px) {
		padding: 40px 35px;
	}

	@media screen and (min-width: 768px) {
		padding: 40px 70px;

		.GlobalMessage_text {
			font-size: 18px;
			line-height: 20px;

			// .GlobalMessage_icon {
			// 	$svgSize : 60px;
			// 	width: $svgSize;
			// }
		}


		.GlobalMessage_close {
			top: 50%;
			right: 15px;
			transform: translate3d(0, -50%, 0) scale(1);
		}
	}
}

// ----------------------------------
// Table WYSIYYG
.main-content-left {
	table {
		width: 100%!important;

		tbody {
			tr {
				&:first-child {
					background: #72A2BD!important;

					td {
						color: $color-white!important;
						padding: 12px 8px;
						font-weight: 600;
						&:not(:last-child) {
							border-right: none!important;
						}
					}
				}

				td {
					padding: 12px 8px;
					color: #333333;
					font-size: 16px;
					line-height: 24px;
					font-family: $font-site;
					font-weight: 300;
					&:not(:last-child) {
						border-right: 1px solid #72A2BD;
					}
				}

				&:nth-child(odd) {
					background: rgba(#E5EDF2, .25);
				}
			}
		}
	}
}

input, textarea, select {
	&:focus {
		border: none!important;
		outline: none!important;
		box-shadow: none!important;
	}
}

// Program Section
.program {
	@extend %clearfix;
	position: relative;
	padding-bottom: 20px;
	@include breakpoint(tablet) {
		padding-bottom: 50px;
	}
	&:after {
		@include overlay($color-lightblue, 1, -1);
		left: 0;
		width: 50%;
		display: block;
		@include breakpoint(tablet) {
			width: 25%;
		}
	}
	.view-all {
		margin-left: 0px;
		margin-top: 22px;
		display: inline-block;
		font-size: $font-h4;
		vertical-align: middle;
		color: $color-primary;
		float: right;
		@include breakpoint(tablet) {
			margin-top: 15px;
		}
		@include breakpoint(md) {
			margin-left: 100px;
			float: none;
			margin-top: 15px;
		}
		p {
			display: none !important;
			@include breakpoint(tablet) {
				display: inline-block !important;
			}
		}
		span {
			margin-left: 10px;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.info-box {
		.panel {
			@include breakpoint(md) {
				margin-right: 6.5%;
				width: 29%;
			}
			@include breakpoint(xlg) {
				width: 415px;
				margin-right: 117px;
			}
			&:last-of-type {
				margin-right: 0;
				@include breakpoint(mobile) {
					margin-bottom: 0;
				}
			}
			.panel-body {
				@include breakpoint(mobile) {
					display: none;
				}
			}
		}
	}
}

.grid-content {
	float: left;
	height: 100%;
	position: relative;
	width: 50%;
}

// Program Grid
// .grid {
//     width: 100%;
//     display: flex;
//     flex-flow: row wrap;
//     overflow: hidden;
//     margin-bottom: 50px;
//     &-content {
//         -moz-box-flex: 0;
//         flex: 0 0 50%;
//         max-width: 50%;
//     }
//     &-row {
//         align-items: stretch;
//         display: flex;
//         flex-flow: row wrap;
//         width: 100%;
//         .grid-item {
//             padding: 100px 0;
//             text-align: center;
//             background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
//             flex: 0 0 100%;
//             max-width: 100%;
//             border-right: 1px solid #fff;
//             border-top: 1px solid #fff;
//             color: #fff;
//             align-self: middle;
//             text-align: center;
//         }
//     }
//     &-column {
//         display: flex;
//         flex: 0 0 100%;
//         max-width: 100%;
//     }
//     &-item {
//         padding: 100px 0;
//         text-align: center;
//         background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
//         flex: 1 1 100%;
//         max-width: 100%;
//         border-right: 1px solid #fff;
//         border-top: 1px solid #fff;
//         color: #fff;
//         text-align: center;
//     }
// }
// Subsciption Section
.subsribe {
	display: block;
	clear: both;
	@extend %clearfix;
}

.subsribe-box {
	padding: 90px;
	width: 100%;
	@include breakpoint(tablet) {
		width: 100%;
		padding: 50px;
	}
	@include breakpoint(md) {
		width: 50%;
		padding: 50px 20px;
	}
	@include breakpoint(xlg) {
		padding: 90px;
	}
	float: left;
	position: relative;
	overflow: hidden;
	z-index: 1;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#02537e+5,02537e+100&1+5,0.8+100 */
	background: -moz-linear-gradient(left, rgba(2, 83, 126, 1) 5%, rgba(2, 83, 126, 0.8) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(2, 83, 126, 1) 5%, rgba(2, 83, 126, 0.8) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(2, 83, 126, 1) 5%, rgba(2, 83, 126, 0.8) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	color: $color-white;
	h2 {
		color: $color-white;
		padding-bottom: 10px;
	}
	.form-group {
		vertical-align: top;
		.form-control {
			height: 40px;
			border-radius: 0;
			border: none;
			width: 270px;
			box-shadow: none
		}
	}
	input[type=submit] {
		border: none;
		height: 40px;
		text-transform: uppercase;
	}
	p {
		color: $color-lightblue;
		line-height: 20px;
		padding-bottom: 20px;
		font-weight: 400;
	}
	// Infoletter Section
	&.infoletter {
		&:after {
			position: absolute;
			content: "";
			display: block;
			background: url('/images/icon-mail.png') no-repeat right center / 100%;
			width: 246px;
			height: 180px;
			right: 90px;
			top: 90px;
			z-index: -1;
			@include breakpoint(tablet) {
				right: 50px;
				top: 40px;
			}
			@include breakpoint(md) {
				right: 90px;
				top: 90px;
			}
		}
	}
	// Subscibe Left
	.subsribe-box-left {
		float: left;
		margin-right: 30px;
	}
	// Subsribe box-right
	.subsribe-box-right {}
	// Brochure Section
	&.brochure {
		position: relative;
		z-index: 1;
		&:after {
			@include overlay($color-primary,
				1,
				-1);
			left: 0;
			width: 25%;
			display: block;
			@include breakpoint(tablet) {
				width: 100%;
			}
			@include breakpoint(md) {
				width: 25%;
			}
		}
		@include breakpoint(tablet) {
			padding: 20px 50px;
		}
		@include breakpoint(md) {
			padding: 50px 20px;
		}
		@include breakpoint(xlg) {
			padding: 90px 50px;
		}
	}
}


/**
 *
 * Box with Filter
 *
 */

 .filter-box {
 	background: $color-lighterblue;
 	padding: 30px 0px;
 	position: relative;
 	z-index: 0;
 	@include breakpoint(md) {
 		padding: 30px 40px;
 	}
 	@include breakpoint(xlg) {
 		padding: 60px 40px;
 	}
 	&:after {
 		@include overlay($color-lighterblue,
 			1,
 			-1);
 		height: 200%;
 		right: 0;
 		left: -200px;
 		width: 1000%;
 		z-index: -1;
 		@include breakpoint(md) {
 			left: 0;
 		}
 	}
 	h4 {
 		margin-bottom: 20px;
 		text-transform: none;
 	}

 	a {
 		&.active {
 			position: relative;
 			background: $color-darkblue;
 			color: $color-white;

 			span {
 				position: absolute;
 				top: -7px;
 				right: -7px;
 				width: 18px;
 				height: 18px;
 				border-radius: 50%;
 				background: $color-secondary;
 				&:before, &:after {
 					content: '';
 					position: absolute;
 					top: 50%;
					left: 50%;
 					width: 2px;
 					height: 10px;
 					background: $color-white;
 				}

 				&:before {
					transform: translate3d(-50%, -50%, 0) rotate(-45deg);
 				}

 				&:after {
					transform: translate3d(-50%, -50%, 0) rotate(-135deg);
 				}
 			}
 			&:hover {
 				border-color: $color-primary;
 				cursor: pointer;
 			}
 		}
 	}
 }


/**
 *
 * Return Link
 *
 */

 .return {
 	span {
 		margin-right: 5px;
 	}
 }


/**
	*
	* Main Content Section
	*
	*/

	.main-content {
		display: block;
		position: relative;

		@extend %clearfix;
		margin-bottom: 50px;
		&-left {
			width: 100%;
			float: left;
			@include breakpoint(md) {
				width: 62%;
			}
			@include breakpoint(xlg) {
				width: 850px;
			}
			p {
				padding-bottom: 20px;
				line-height: 28px;
				@include breakpoint(xlg) {
					padding-bottom: 30px;
				}
			}
			h3 {
				color: $color-secondary;
				padding-bottom: 5px;
			}

			ul {
				// list-style: none;
				padding-left: 20px;
				li {
					padding-bottom: 15px;
					line-height: 28px;
					font-size: $font-size-body - 1px;
					@include breakpoint(tablet){
						font-size: $font-size-body;
					}
					.material-icons {
						padding-right: 10px;
						font-size: 19px;
						vertical-align: top;
						color: $color-primary;
					}
				}
			}
		}
		&-right {
			float: right;
			width: 100%;
			@include breakpoint(md) {
				width: 29%;
			}
			@include breakpoint(xlg) {
				width: 415px;
			}
			.program-detail-box {
				margin-top: 0;
				@include breakpoint(md) {
					margin-top: 0px;
				}
				@include breakpoint(lg) {
					margin-top: 100px;
				}
				@include breakpoint(xlg) {
					margin-top: 200px;
				}
				.panel {
					width: 100%;
				}
			}
		}
		&.detail {
			padding-bottom: 200px;
			@include breakpoint(mobile) {
				padding-bottom: 0px;
			}
		}
		@at-root body.contact-page & {
			margin-bottom: 0;

		}
	}

	.blockquote {
		padding: 20px 40px;
		margin-bottom: 20px;
		background: $color-lightblue;
	}


	/* Program */

	.program-block {
		margin: 0;
		padding: 0;
		li {
			@include rowMachine(1,
				0%,
				5%);
			float: left;
			list-style: none;
			@include breakpoint(tablet) {
				@include rowMachine(2,
					5%,
					5%);
			}
			@include breakpoint(md) {
				@include rowMachine(3,
					10%,
					5%);
			}
		}
	}


	/* Team */
	.team-block {
		margin: 0;
		padding: 0;
		list-style: none;
		
		display: flex;
		flex-direction: column;

		li {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		@media screen and (min-width: 768px) {
			margin: 0 -15px;

			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			li {
				padding: 0 15px;
				width: 50%;
				margin-bottom: 20px;
			}
		}

		@media screen and (min-width: 1024px) {
			li {
				width: calc(100% / 3);
			}
		}
	}


	/* Event */

	.event-block {
		margin: 0;
		padding: 0;
		list-style: none;
		
		display: flex;
		flex-direction: column;

		li {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		@media screen and (min-width: 768px) {
			margin: 0 -15px;

			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			li {
				padding: 0 15px;
				width: 50%;
				margin-bottom: 20px;
			}
		}

		@media screen and (min-width: 1024px) {
			li {
				width: calc(100% / 3);
			}
		}

		// li {
		// 	float: left;
		// 	list-style: none;
		// 	@include rowMachine(1,
		// 		0%,
		// 		5%);
		// 	float: left;
		// 	list-style: none;
		// 	@include breakpoint(tablet) {
		// 		@include rowMachine(2,
		// 			5%,
		// 			5%);
		// 	}
		// 	@include breakpoint(md) {
		// 		@include rowMachine(3,
		// 			6%,
		// 			6%);
		// 	}
		// }
	}


	/* Share */

	.share {
		font-size: 0;
		a {
			height: 50px;
			vertical-align: top;
			margin-right: 0;
		}
		i {
			font-size: 20px;
		}
		@media only screen and (max-width:1300px) {
			float: left;
			width: 100%;
			margin-top: 0;
			padding-bottom: 30px;
		}
	}

// Event Detail
.event-time {
	float: left;
	width: 100%;
}

// Grid Section
.grid {
	overflow: hidden;
	margin-bottom: 30px;
	@include breakpoint(tablet) {
		margin-bottom: 60px;
	}
	// Grid Row
	.grid-row {
		float: left;
		height: 100%;
		position: relative;
		width: 100%;
	}
}

// Grid Content
.grid-content {
	float: left;
	height: auto;
	position: relative;
	width: 100%;
	@include breakpoint(tablet) {
		width: 100%;
		height: 500px;
	}
	@include breakpoint(md) {
		width: 50%;
		height: 555px;
	}
	// Grid Column
	.grid-column {
		float: left;
		height: 100%;
		left: 0;
		margin: auto;
		position: relative;
		right: 0;
		text-align: left;
		width: 100%;
		@include breakpoint(tablet) {
			position: absolute;
			text-align: center;
		}
	}
	.grid-row {
		float: left;
		width: 100%;
		@include breakpoint(tablet) {
			width: 50%;
		}
	}
	// Grid Left Section
	&.grid-left {
		background: none;
		@include breakpoint(tablet) {
		}
		.grid-row {
			// Grid Left First Child
			&:first-child {
				height: auto;
				@include breakpoint(tablet) {
					height: 50%;
				}
			}
			// Grid Left Last Child
			&:last-child {
				height: auto;
				@include breakpoint(tablet) {
					height: 100%;
				}
			}
		}
		.grid-column {
			&:first-child {
				height: auto;
				top: 0;
				@include breakpoint(tablet) {
					height: 65%;
				}
				@include breakpoint(md) {
					height: 70%;
				}
			}
		}
		// Grid Left Last column
		.grid-column {
			&:last-child {
				height: auto;
				top: 70%;
				@include breakpoint(tablet) {
					height: 35%;
					top: 65%;
				}
				@include breakpoint(md) {
					height: 30%;
					top: 70%;
				}
			}
		}
	}
	// Grid Left Section
	&.grid-right {
		background: none;
		border-bottom: 1px solid $color-primary-50;
		@include breakpoint(tablet) {
			border-bottom: none;
		}
		.grid-row {
			&:first-child {
				.grid-item-outer {
					&:first-child {
						height: auto;
						@include breakpoint(tablet) {
							height: 35%;
						}
						@include breakpoint(md) {
							height: 30%;
						}
					}
					&:last-child {
						height: auto;
						@include breakpoint(tablet) {
							height: 65%;
						}
						@include breakpoint(md) {
							height: 70%;
						}
					}
				}
			}
			&:last-child {
				.grid-item-outer {
					&:first-child {
						height: auto;
						@include breakpoint(tablet) {
							height: 65%;
						}
						@include breakpoint(md) {
							height: 70%;
						}
					}
					&:last-child {
						height: auto;
						@include breakpoint(tablet) {
							height: 35%;
						}
						@include breakpoint(md) {
							height: 30%;
						}
					}
				}
			}
		}
	}
	.grid-item-outer {
		background: rgba(255, 255, 255, 1) none repeat scroll 0 0;
		border-top: 1px solid $color-primary-50;
		float: left;
		height: 100%;
		position: relative;
		width: 100%;
		padding: 15px 0;
		color: $color-primary;
		font-weight: 500;
		overflow: hidden;
		@include breakpoint(tablet) {
			padding: 0;
			background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
			color: $color-white;
			border: none;
			border-right: 1px solid #fff;
			border-top: 1px solid #fff;
		}

		.typeImg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			opacity: 0;
			transition: all ease-in .3s;
		}

		// &:after {
		//   display: none;
		//   @include breakpoint(tablet) {
		//     display: block;
		//   }
		//   @include overlay($color-primary,
		//   0,
		//   0);
		//   height: 0;
		//   width: 0;
		//   @extend %transition-2s;
		//   left: 0;
		//   right: 0;
		//   top: auto;
		//   bottom: auto;
		// }
		.grid-item {
			height: auto;
			left: 0%;
			position: relative;
			top: 0%;
			transform: translate(-0%, -0%);
			font-size: $font-h2 - 2px;
			font-weight: 500;
			line-height: 30px;
			width: 100%;
			z-index: 1;
			padding-left: 20px;
			@include breakpoint(tablet) {
				position: absolute;
				transform: translate(-50%, -50%);
				left: 50%;
				top: 50%;
				padding-right: 20px;
				font-size: $font-h2 + 2px;
				font-weight: 900;
			}
		}
		&:hover {

			@include breakpoint(tablet) {
				background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
			}

		}
		// Icon on Mobile
		.icon {
			float: right;
			margin-top: 0;
			border: 1px solid $color-primary-75;
			i {
				color: $color-secondary;
				padding-left: 3px;
				padding-top: 3px;
				@include breakpoint(tablet) {
					color: $color-white;
					padding-left: 0px;
					padding-top: 0;
					&:hover{
						color: $color-secondary;
					}
				}

			}
			@include breakpoint(tablet) {
				float: none;
				margin-top: 10px;
				border: 1px solid $color-white;
			}
		}
	}
}

// -----------------------------
// Pagination
.PaginationWrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0;
	padding: 0;
	list-style: none;

	li {

		&.active {
			background: $color-skyblue;
			a {
				color: $color-white;
			}
		}

		a {
			padding: 10px 15px;

			svg {
				transform: rotate(-90deg) scale(1.5) translateX(2px);
			}
		}
	}
}


// -------------------------------
// Calendar

$currentDay : #63992E;

.calendarWrapper {
	.ui-datepicker {
		width: 100%;
		border: none!important;
		border-radius: 0;
		background: transparent;

		// header
		.ui-datepicker-header {
			border: none!important;
			background: transparent;
			padding-bottom: 20px;

			a {
				width: 25px;
				height: 25px;
				border: 1px solid $color-primary;
				border-radius: 50%;
				font-size: 0;

				span {
					position: static;
					display: block;
					width: 100%;
					height: 100%;
					margin: 0;
					background-image: none!important;
					&.ui-icon {
						text-indent: 0px!important;
					}
				}

				&.ui-datepicker-prev {
					span {
						&:before {
							content: url('../../img/ic-arrow-left.svg');
							display: block;
							width: 19px;
							height: 7px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}
					}
				}

				&.ui-datepicker-next {
					span {
						&:before {
							content: url('../../img/ic-arrow-right.svg');
							display: block;
							width: 19px;
							height: 7px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}
					}
				}
			}

			.ui-datepicker-title {
				span {
					font-weight: bold;
					font-size: 18px;
					line-height: 21px;
					color: $color-primary;
					font-family: $font-site;
				}
			}
		}
		// header END

		// table
		.ui-datepicker-calendar {
			background: $color-white;

			thead {
				tr {
					th {
						&:not(:last-child) {
							border-right: 1px solid $color-white;
						}

						background: #02537E;

						span {
							color: $color-white;
							font-weight: bold;
							font-size: 12px;
							line-height: 15px;
							font-family: $font-site;
						}

						&.ui-datepicker-week-end {
							background: #72A2BD;
						}
					}
				}
			}

			tbody {

				.ui-state-hover {
					border: none!important;
					background-color: transparent!important;
				}

				tr {
					&:not(:last-child) {
						border-bottom: 1px solid $color-lighterblue;
					}

					td {
						padding: 20px 5px;
						opacity: 0.9999;
						text-align: center;
						position: relative;
						// overflow: hidden;
						&.highlight-tooltip {
							span {
								color: $color-white;
							}
						}

						.InfosWrapper {
							display: none;
						}

						// if has bottom-tooltip
						// &.bottom-tooltip {
						// 	&[class*="calendar-"] {
						// 		.InfosWrapper {
						// 			.Infos {
						// 				// bottom: 5px;
						// 				top: 90%;
						// 				// height: 100%;

						// 				.Info {
						// 					background: $color-white;
						// 				}
						// 			}
						// 		}
						// 	}
						// }


						&[class*="calendar-"] {

							@media screen and (max-width: 768px) {
								.InfosWrapper {
									.Infos {
										width: 180px!important;
										// width: 100%!important;

										.Info {
											width: 100%!important;
											padding: 6px!important;

											.left {
												width: 100%!important;
												padding: 0px!important;
												border-right: none!important;

												p {
													font-size: 12px!important;
													line-height: 14px!important;
												}
											}

											.right {
												display: none;
											}
										}
									}
								}
							}

							.InfosWrapper {
								position: absolute;
								display: block;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								z-index: 50;

								.Infos {
									position: absolute;
									bottom: 100%;
									left: 50%;
									transform: translate3d(-50%, 0, 0);
									background: $color-white;
									opacity: 0;
									pointer-events: none;
									box-shadow: 0 -2px 7px 0 rgba(2,83,126,0.5);
									&:after {
										content: '';
										position: absolute;
										top: 100%;
										left: 50%;
										transform: translate3d(-50%, 0, 0);
										width: 0;
										height: 0;
										border-style: solid;
										border-width: 10px 12px 0 12px;
										border-color: lighten(#02537E, 5%) transparent transparent transparent;
									}

									.Info {
										display: flex;
										align-items: center;
										border: 1px solid #02537E;
										width: 335px;
										padding: 6px;

										.left {
											border-right: 1px solid #CDDCE6;
											width: calc(100% - 45px);
											padding-right: 0;

											p {
												margin: 0;
												font-size: 16px;
												line-height: 17px;
												color: #02537E;
												font-weight: 500;

												a {
													text-align: left;
												}
											}	
										}

										.right {
											padding-left: 10px;
											width: 45px;

											a {
												display: block;
												width: 30px;
												height: 30px;
												background: url('../../img/arrowyes.jpg');
												background-size: cover;
												background-position: center center;
												transform: translate3d(0, 0, 0);
												transition: all ease .3s;
											}
										}

										&:first-child {
											border-top: 1px solid #02537E!important;
										}
										&:last-child {
											border-top: none;
										}

										&:hover {
											.right {
												a {
													transform: translate3d(5px, 0, 0);
												}
											}
										}
									}
								}

								&:hover, &:focus {
									.Infos {
										opacity: 1;
										pointer-events: auto;
										transition: all ease-in .3s .25s;
									}
								}
							}


							.ui-state-default {
								color: $color-white!important;
							}
						}

						// if is extended
						&.calender-extended {

							// extented AND current day
							&.ui-datepicker-current-day {

								.ui-datepicker-current-day {
									color: $color-primary!important;
								}
							}

							.ExtendedWrapper {
								opacity: .3;
								z-index: 10;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0);
								width: 100%;
								height: 35px;
							}

							&.first {
								.ExtendedWrapper {
									width: 50%;
									transform: translate3d(0, -50%, 0);
								}
							}

							&.middle {
								.ui-state-default {
									color: $color-primary!important;
								}

								.BgColor {
									display: none;
								}
								//if has an other event
								&[class*="calendar-"] {
									.BgColor {
										background: transparent!important;
									}
								}
							}

							&.last {
								.ExtendedWrapper {
									width: 50%;
									transform: translate3d(-100%, -50%, 0);
								}
							}
						}

						.ui-state-default {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
							text-align: center;
							z-index: 20;
							border: none;
							background: transparent;
							color: #02537E;
							font-weight: 500;
							font-size: 13px;
							line-height: 1;
							font-family: $font-site;
							display: inline-block;
							border-radius: 50%;
						}

						.BgColor {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
							width: 35px;
							height: 35px;
							border-radius: 50%;
							z-index: 18;
						}

						// current day
						&.ui-datepicker-today {
							.ui-state-default {
								color: $color-white;
							}

							.BgColor {
								display: block!important;
								color: $color-white;
								background: $currentDay;
							}

							&[class*="calendar-"] {
								.ui-state-default {
									// color: $color-white!important;
								}
								.BgColor {
									// background: transparent!important;
									&:before {
										content: '';
										position: absolute;
										bottom: 2px;
										left: 50%;
										transform: translateX(-50%);
										width: 6px;
										height: 6px;
										border-radius: 50%;
										background: $currentDay;
									}
								}
							}
						}

						&.ui-state-disabled {
							pointer-events: auto!important;
						}

						// calendar-extended
						// &.calendar-extended {
						// 	overflow: hidden;

						// 	&.first {
						// 		span {
						// 			position: relative;
						// 			&:before {
						// 				content: '';
						// 				position: absolute;
						// 				right: -50px;
						// 				top: 0;
						// 				bottom: 0;
						// 				width: 67px;
						// 				background: red;
						// 				z-index: 0;
						// 			}
						// 		}
						// 	}

						// }

					}
				}
			}
		}
		// table END
	}
}

.event-color-detail {
	list-style: none;
	margin: 0;
	padding: 15px 0 0 0;
	display: flex;
	flex-wrap: wrap;

	li {
		padding: 15px 15px 0 0;
		width: 100%;
		margin: 0;
		color: #02537E;
		font-weight: bold;
		font-size: 12px;
		line-height: 12px;
		font-family: $font-site;
		text-transform: uppercase;
		// &:not(:last-child) {
		// 	margin-right: 30px;
		// }

		span {
			display: inline-block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-right: 4px;
		}
		
		&.current-day {
			span {
				background: $currentDay;
			}
		}

	}

	// 480px +
	@media screen and (min-width: 480px) {
		li {
			width: 50%;
		}
	}

	// 768px +
	@media screen and (min-width: 768px) {
		padding: 15px 0 75px;

		li {
			width: calc(100% / 4);
		}
	}

	// 1024px +
	@media screen and (min-width: 1024px) {
		li {
			width: 50%;
		}
	}
}


// -------------------------------
// Accordions

.Accordions{
	margin-top: 4em;
	margin-bottom: 8em;
	border-top: 2px solid lighten($color-black, 80%);
	border-bottom :2px solid lighten($color-black, 80%);

	.Accordions_block {
		padding-top: 25px;
		padding-bottom: 25px;
		&:not(:last-child){
			border-bottom: 2px solid lighten($color-black, 80%);
		}
		p:last-child{
			margin: 0;
		}
		.Accordions_header {
			.Accordions_title{
				margin: 0;
				font-weight: 500;
				font-size: 21px;
				text-transform: uppercase;
				line-height: 25px;
				font-family: $font-site;
				color: $color-primary;
			}
			// active
			&.active{
				.Accordions_title{
					transition: all ease .3s;
					&:before{
						color: $color-primary;
						transition: all ease .3s;
					}
				}
			} // active END

		} // _header END
		
		.Accordions_content {
			margin-top:1.3rem;
			display:none;
			
		} // _content END

	} // _block END
}


// -------------------------------
// dev-box

.dev_box {
	svg {
		max-width: 35px;
		margin-right: 15px;
		path, rect {
			fill: black;
		}
	}
}






















