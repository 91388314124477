.sec {
  display: block;
  clear: both;
  @extend %clearfix;
  overflow: hidden;
}

.title-sec {
  background: $color-lightblue;
  position: relative;
  margin-bottom: 50px;
  padding: 30px 0;
  @include breakpoint(md) {
    padding: 0;
  }
  &-left {
    padding: 0px 0px 20px 0;
    width: 100%;
    float: left;
    @include breakpoint(mobile) {
      padding: 0px 0 20px;
      width: 100%;
    }
    @include breakpoint(md) {
      padding: 50px 40px 0 0;
      width: 65%;
    }
    @include breakpoint(xlg) {
      padding: 80px 40px 0 0;
    }
    p {
      line-height: 28px;
    }
    h1 {
      margin-bottom: 15px;
      @include breakpoint(mobile) {
        margin-bottom: 20px;
        width: 100%;
      }
      @include breakpoint(tablet-only) {
        width: 100%;
      }
      @media only screen and (max-width:1300px){
        width: auto;
        padding-right: 20px;
      }
    }
    @at-root .full {
      width: $width-container-fluid;
      .title-sec-left {
        width: $width-container-fluid;
        padding-right: 0;
        padding-bottom: 80px;
      }
    }
  }
  &-right {
    width: 100%;
    float: right;
    @include breakpoint(mobile) {
      padding: 0px;
    }
    @include breakpoint(md) {
      width: 35%;
    }
  }
  &-w-img {
    overflow: visible;
    min-height: 1px;
    @include breakpoint(md) {
      min-height: 260px;
    }
    @include breakpoint(xlg) {
      min-height: 286px;
    }
    .title-sec-left {
      padding-bottom: 20px;
      @include breakpoint(mobile) {
        padding-bottom: 0px;
      }
    }
    .title-sec-right {
      position: relative;
      right: 0;
      @include breakpoint(lg) {
        position: absolute;
      }
      img {
        position: relative;
        right: 0;
        @include breakpoint(mobile) {
          width: 100%;
        }
        @include breakpoint(tablet-only) {
          margin: 0 auto 20px;
          display: block;
        }
        @include breakpoint(lg) {
          position: absolute;
        }
      }
    }
  }
  @at-root body.contact-page & {
    margin-bottom: 0;
  }
}

.single-header-subtitle {
  font-size: 19px;

  @media (min-width: 1025px) {
    font-size: 21px;
  }
}
