// ----------------------------------
// StickyNav BEGIN
.StickyNav {
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	width: 100%;
	background: $color-primary;
	display: flex;
	justify-content: space-between;
	transition: all $ease-in .5s;
	transform: translateY(-100%);
	&.is-visible {
		transform: translateY(0);
	}

	.StickyNav_socials {

	}

	.Sticky_nav_contact {
		nav {
			ul {
				margin: 0;
				padding: 0;

				a {
					font-size: 16px;
					padding: 10px 12px;

					@media screen and (min-width: 768px) {
						font-size: 20px;
						padding: 10px 20px;
					}

					@media screen and (min-width: 1024px) {
						font-size: 22px;
					}
				}
			}
		}

		.phone-no {
	  		font-size: $font-h2 - 2px;
	  		padding: 10px 15px;
	  		display: none;
	  		@include breakpoint(tablet) {
	  			display: inline-block !important;

	  		}
	  		@include breakpoint(md) {
	  			font-size: $font-h2 ;
	  		}
	  		color: $color-white;
	  		&:hover {
	  			color: $color-secondary;
	  		}
	  	}
	}
}
// StickyNav END
// ----------------------------------

header {
	position: relative;
	.navbar-header {
		width: 100%;
		text-align: center;
		float: left;
		margin-top: 40px;
		margin-left: 0 !important;
		margin-right: 0!important;
		@include breakpoint(tablet) {
			width: 25%;
			margin-top: 0px;
		}
	}
	.logo-section {
		text-align: center;
		@media screen and (min-width: 768px) and (max-width: 1024px) {
			height: 95px;
			display: flex;
			align-items: center;
			justify-content: center;
			.logo {
				margin: 0;
			}
		}
	}
	.logo {
		margin: 20px;
		display: inline-block;
		height: auto;
		padding: 0;
		float: none;
		@include breakpoint(tablet) {
			margin-top: 30px ;
		}
		@include breakpoint(md) {
			margin: 30px 20px;
		}
		@include breakpoint(lg) {
			margin: 30px 40px;
		}
		@include breakpoint(xlg) {
			margin: 30px 40px;
		}
		img {
			width: 80%;
			display: block;
			margin: 0 auto;
			@include breakpoint(tablet) {
				width: 80%;
			}
			@include breakpoint(md) {
				width: auto;
			}
			&.logo-tablet {
				display: none;
				@include breakpoint(tablet) {
					display: block;
					max-width: 120px;
				}
				@include breakpoint(md) {
					display: none;
				}
			}
			&.logo-desktop {
				display: block;
				@include breakpoint(tablet) {
					display: none;
				}
				@include breakpoint(md) {
					display: block;
				}
			}
		}
	}
  // Header Right
  .header-right {
  	float: right;
  	width: 100%;
  	border: none;
  	border-top: 1px solid $color-lightblue;
  	@include breakpoint(tablet) {
  		width: 75%;
  		border-top: none;
  		border-left: 1px solid $color-lightblue;
  	}
	// Header Right Top
	&-top {
		background: $color-primary;
		position: absolute;
		top: 0;
		width: 100%;
		@extend %clearfix;
		@include breakpoint(tablet) {
			position: relative;
		}
	  // Button
	  .btn {
	  	font-size: $font-h2 - 6;
	  	@include breakpoint(tablet) {
	  		font-size: $font-h2 - 2;
	  	}
	  	@include breakpoint(md) {
	  		font-size: $font-h2;
	  	}
	  }
	}
	// Header Right bottom
	&-bottom {
		padding: 0;
		float: right;
		margin-right: 0px;
		width: 100%;
		margin-right: 0;
		@include breakpoint(tablet) {
		// padding: 35px 0;
		margin-right: 20px;
		width: auto;
	}
	@include breakpoint(md) {
		// padding: 15px 0;
		margin-right: 30px;
	}
	@include breakpoint(lg) {
		// padding: 20px 0;
		margin-right: 80px;
	}
	@include breakpoint(xlg) {
		// padding: 35px 0;
		margin-right: 120px;
	}
	.navbar-collapse {
		float: left;
		display: none !important;
		@include breakpoint(md) {
			display: block!important;
		}
	}
}
.search-icon {
	color: $color-primary;
	font-size: $font-h2 + 10px;
}
}
  // Contact Info
  .contact-info {
  	float: right;

  	nav > ul {
  		margin: 0;
  		padding: 0;

  		a {
  			padding: 10px 12px;
  			@media screen and (min-width: 768px) {
				padding: 10px 20px;
			}
  		}
  	}
  	.phone-no {
  		font-size: $font-h2 - 2px;
  		padding: 10px 15px;
  		display: none;
  		@include breakpoint(tablet) {
  			display: inline-block !important;

  		}
  		@include breakpoint(md) {
  			font-size: $font-h2 ;
  		}
  		color: $color-white;
  		&:hover {
  			color: $color-secondary;
  		}
  	}
  }
  .container-fluid {
  	padding-left: 0;
  	padding-right: 0;
  }
}
